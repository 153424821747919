.play-icon {
    position: relative;
    display: inline-block;
    cursor: pointer;
	vertical-align: top;
}

.film-title {
    display: block;
    margin-bottom: 5px; /* Vous pouvez ajuster cette valeur pour augmenter/diminuer l'espace entre le titre et l'icône */
}

.trailer-popup {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    width: 560px;
    height: 315px;
}

.play-icon:hover .trailer-popup {
    display: block;
}
.App {
  text-align: center;
  font-family: 'Helvetica', 'Arial', 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.search-input {
    font-size: 18px;
    padding: 10px 15px;
    width: 100%;
    max-width: 400px;
    margin: 20px 0;
    border: 1px solid #0184e6;
    border-radius: 15px;
}

.page-title {
    font-size: 40px;
	color: #0184e6;
    font-weight: bold;
    text-align: center;
    margin: 30px 0;
}

.page-title a {
    color: inherit; /* hérite de la couleur du texte parent */
    text-decoration: none; /* supprime le soulignement */
}

.page-description {
    font-size: 24px;
    color: #4b5258;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5;
}

.footer {
    padding: 10px;
    background-color: #f4f4f4;
    text-align: center;
	font-size: 18px;
    margin-top: 10px;
    border-top: 1px solid #e4e4e4;
}

.footer a {
    color: #007BFF; /* Bleu par défaut pour les liens, mais vous pouvez le changer */
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
	margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.pagination button {
    padding: 10px 15px;
    margin: 0 5px;
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #0056b3;
}

.pagination select {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 680px) {
    table {
        font-size: 15px !important;
        max-width: 100%;
        overflow-x: auto;
        display: block;
    }
}
